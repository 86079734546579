/* eslint-disable react-hooks/exhaustive-deps */
import { EventActions, UserActions, HubActions } from "../../store/actions";

import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { scrollToElem } from "@/utils/interfaceHelpers.js";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { CSSTransition } from "react-transition-group";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Jumbotron from "react-bootstrap/Jumbotron";

import EventFilterGroupContainer from "../../containers/EventFilterGroupContainer";
import HubFilterListContainer from "../../containers/HubFilterListContainer";

import { HeroBanner } from "../../components/HeroBanner";

import { ErrorPage } from "../ErrorPage";

import "./ProductLandingPage.scss";

const {
  loadEventFilterGroup,
  loadProductTopicGroup,
  updateEventFiltersSilent,
} = EventActions;

const { clearHubEventFilters } = HubActions;

const { getUserFavoritesAll } = UserActions;

const ProductLandingPage = ({
  user,
  listInit,
  listCurrent,
  timezone,
  hubId,
  hubConfig,
  topicList,
  favorites,
  tb_id,
  hubFeaturedEvent,
  notFoundId,
  history,
  language,
  isLoading,
  location,
  appConfig,
  criticalError,
  dispatch,
}) => {
  const { t } = useTranslation();
  const expertCoachingProducts = () => {
    const productFilter = "&f[successeventproduct]=";
    switch (hubId) {
      case "analytics":
        return productFilter + "crm-analytics,tableau";
      case "datacloud":
        return productFilter + "data-cloud";
      case "experiences":
        return productFilter + "experience";
      case "industries":
        return productFilter + "communications,consumer-goods,financial-services,healthcare-%26-life-sciences,industries,manufacturing,strategy-%26-planning";
      case "marketing":
        return productFilter + "marketing,marketing-cloud-account-engagement,marketing-cloud-intelligence,marketing-cloud-personalization";
      case "mulesoft":
        return productFilter + "integration";
      case "slack":
        return "";
      default:
        break;
    }
    return productFilter + hubId;
  }
  const expertCoachingFilter = expertCoachingProducts();

  useEffect(() => {
    //console.log("loading groups");
    loadProductFilterGroups();
    loadProductTopicGroups();

    if (tb_id && !hasFavoritesInit) {
      dispatch(getUserFavoritesAll({ tb_id }));
      setHasFavoritesInit(true);
    }

    // clear search terms on homepage
    const filterObjectSearch = { key: "search", values: null };
    dispatch(updateEventFiltersSilent(filterObjectSearch));
  }, [hubId, location]);

  useEffect(() => {
    if (
      hubConfig &&
      hubConfig.filterConfig &&
      hubConfig.filterConfig.userFiltersActive !== undefined
    ) {
      setUserFiltersActive(hubConfig.filterConfig.userFiltersActive);
      setUserFiltersActiveNum(hubConfig.filterConfig.userFiltersActiveNum);
      setHasUserFiltersInit(true);
    }
  }, [hubConfig]);

  //const [hasInit, setHasInit] = useState(false);
  const [hasFavoritesInit, setHasFavoritesInit] = useState(false);
  const [userFiltersActive, setUserFiltersActive] = useState(false);
  const [userFiltersActiveNum, setUserFiltersActiveNum] = useState(false);

  const [hasUserFiltersInit, setHasUserFiltersInit] = useState(false);

  const loadProductFilterGroups = () => {
    productFilterGroups.map((group, index) =>
      dispatch(loadEventFilterGroup(group))
    );
  };

  const loadProductTopicGroups = () => {
    const group = {
      businessLine: hubId,
    };
    dispatch(loadProductTopicGroup(group));
  };

  const seeAllEvents = () => {
    scrollToElem("hubGroupHeader");
  };

  const clearAllHandler = (key, value) => {
    let filterObject = { hubId: hubId };
    //console.log(filterObject);
    dispatch(clearHubEventFilters(filterObject));
  };

  const productLines = appConfig.productLines[hubId];
  //console.log("business line: ", hubId);
  //console.log("product lines", appConfig.productLines[hubId]);

  const productFilterGroups = [
    {
      groupkey: "group-upcoming-events",
      filters: {
        product: productLines,
      },
      title: "PLP Featured Upcoming Events",
    },
    {
      groupkey: "group-on-demand-events",
      filters: {
        product: productLines,
        type: ["video"],
      },
      title: "PLP Recent On-Demand Events",
    },
  ];

  const hubDetail = {
    slug: hubId,
    filter_config: {
      mode: "product",
      product: productLines,
    },
  };

  //console.log("topic list");
  //console.log(topicList);

  let productTopics = {};
  if (topicList) {
    topicList.forEach((portrait) => {
      if (!productTopics.hasOwnProperty(portrait.subPhase)) {
        productTopics[portrait.subPhase] = [];
      }
      //console.log(portrait.title);
      productTopics[portrait.subPhase].push({
        title: portrait.title,
        link: `/portraits/${portrait.portraitID}`,
      });
    });
  }
  //console.log(productTopics);

  const pageTitle =
    t(`Product Line Title ${hubId}`) + " - " + t("Customer Success Events");

  if (criticalError) {
    return <ErrorPage errorType="500" />;
  }

  if (productLines === undefined) {
    return <ErrorPage errorType="404" />;
  }

  const RenderProductFilterGroup = ({ start, end, mode = "product" }) => {
    return (
      <Container>
        <Row>
          <Col xs={12} lg={12}>
            {productFilterGroups.slice(start, end).map((group, index) => (
              <EventFilterGroupContainer
                groupKey={group.groupkey}
                groupConfig={group}
                key={group.groupkey}
                mode={mode}
              />
            ))}
          </Col>
        </Row>
      </Container>
    );
  };

  const RenderExpertCoachingTopicsSection = () => {
    if (Object.keys(productTopics).length === 0) {
      return <></>;
    } else {
      const sectionHeights = {};
      Object.entries(productTopics).forEach((topic) => {
        const [key, value] = topic;

        if (value.length > 9) {
          sectionHeights[key] = "tall";
        } else if (value.length > 4) {
          sectionHeights[key] = "medium";
        } else {
          sectionHeights[key] = "small";
        }
      });
      return (
        <Container>
          <Row>
            <Col xs={12}>
              <div className="expert-coaching-section">
                <div className="expert-coaching-section--header">
                  <h3 className="groupHeader">
                    {t("PLP Expert Coaching Topics")}
                  </h3>
                </div>
                {productTopics &&
                  Object.keys(productTopics).map((key) => (
                    <>
                      <h4>{t(`Portrait Phase ${key}`)}</h4>
                      <div className={`topics ${sectionHeights[key]}`}>
                        {productTopics[key].map((portrait) => (
                          <Link key={portrait.portraitID} to={portrait.link}>
                            {portrait.title}
                          </Link>
                        ))}
                      </div>
                    </>
                  ))}
              </div>
            </Col>
          </Row>
        </Container>
      );
    }
  };

  const RenderDiscoverExpertCoachingTopicsSection = () => {
    return (
      <div className="discoverExpertCoaching">
        <h2 className="groupHeader">{t("Discover Expert Coaching Topics")}</h2>
        <p className="eccDescription">{t("Explore the New Digital Catalog with engagements to help you get more value from Salesforce Products")}</p>
        <Link
          to={{
            pathname: "/search",
            hash: "#tab=Coaching" + expertCoachingFilter,
          }}
          className="btn btn-primary"
        >
          {t("Explore All Events")}
        </Link>
      </div>
    );
  }

  const metaDescription = t("Default Meta Description");

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="og:description" content={metaDescription} />
      </Helmet>
      <HeroBanner
        minHeight="379px"
        eventProductKey="plp"
        eventTypeKey={`plp-${hubId}`}
        user={user}
      >
        <Row>
          <Col xs={10} md={6} lg={6}>
            <Jumbotron>
              <h1>{t(`Product Line Title ${hubId}`)}</h1>
              <p>{t(`Product Line Description ${hubId}`)}</p>

              {!userFiltersActive ? (
                <Button variant="primary" onClick={seeAllEvents}>
                  {t("See All Events")}
                </Button>
              ) : (
                <>
                  <Button variant="secondary" onClick={seeAllEvents}>
                    {`(${userFiltersActiveNum}) ${
                      userFiltersActiveNum > 1
                        ? t("Filters Applied")
                        : t("Filter Applied")
                    }`}
                  </Button>

                  <button
                    type="button"
                    className="heroSubLink btn btn-link"
                    onClick={() => {
                      clearAllHandler();
                    }}
                  >
                    {t("Clear All Filters")}
                  </button>
                </>
              )}
            </Jumbotron>
          </Col>
        </Row>
      </HeroBanner>
      <div id="filterContentStart"></div>

      {hasUserFiltersInit && (
        <>
          <CSSTransition
            appear
            in={!userFiltersActive}
            timeout={100}
            classNames="panel"
            unmountOnExit
          >
            <RenderProductFilterGroup start={0} end={1} mode="featured" />
          </CSSTransition>
          {/* <CSSTransition
            appear
            in={!userFiltersActive}
            timeout={100}
            classNames="panel"
            unmountOnExit
          >
            <RenderExpertCoachingTopicsSection />
          </CSSTransition> */}
          <CSSTransition
            appear
            in={!userFiltersActive}
            timeout={100}
            classNames="panel"
            unmountOnExit
          >
            <RenderProductFilterGroup start={1} end={2} mode="bottomborder" />
          </CSSTransition>
          <CSSTransition
            appear
            in={!userFiltersActive}
            timeout={100}
            classNames="panel"
            unmountOnExit
          >
            <RenderDiscoverExpertCoachingTopicsSection />
          </CSSTransition>
        </>
      )}

      <HubFilterListContainer
        key={`hublist-${hubId}`}
        businessLineId={hubId}
        hubDetail={hubDetail}
        numPerPage={10}
      />
    </div>
  );
};

export default ProductLandingPage;
