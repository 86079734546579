/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect } from "react-redux";
// user action creator
import { UserActions } from "../store/actions";
// interstitial page
import { LoginPage } from "../pages/LoginPage";
// CA, JP, US state options
import { getStateOptions } from "@/components/EventForm/helpers";
// TODO: check variable assignments and env vars
// TODO: rm mocks
import {
  CLIENT_DOMAIN,
  IS_MOCK_TBID_USER,
  IS_LOCAL,
} from "../config/environment";

import {getPreferredLanguage} from "@/utils/userHelper"
import { updateEventFilters, updateEventLanguageFilter } from "@/store/actions/event";
import {  updateHubLanguageFilter } from "@/store/actions/hub";
// mapDisptachToProps
const { setUserInfo, setUserApiToken, setUserLanguage } = UserActions;
// utility function
const stringReplacer = (value) => {
  return typeof value === "string" ? value.split("+").join(" ") : value;
};
// dispatch from connect()
const TBIDComplete = ({ dispatch }) => {
  useEffect(async () => {
    // get user info
    try {
      console.log(`TBIDComplete`);
      // get url params code, url, and state
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      const communityUrl = urlParams.get("sfdc_community_url");
      const requestState = urlParams.get("state") ?? "";

      // TODO: Remove Mock
      const mockUrl = IS_LOCAL
        ? "http://localhost:8080/auth/tbiduser-mock"
        : "/auth/tbiduser-mock";
      // get user info
      const user = await fetch(
        IS_MOCK_TBID_USER
          ? mockUrl
          : `/auth/tbiduser?code=${code}&sfdc_community_url=${communityUrl}&state=${requestState}`,
        {
          method: "GET",
        }
      )
        .then((user) => user.json())
        .catch((e) => console.log(e));
      // if we have a user and user first_name, initialize
      // else redirect to home as default
      // console.log(`USER`);
      // console.log(JSON.stringify(user));
      if (user && user.first_name) {
        // console.log(`IF USER and FIRST NAME`);
        // country
        let userCountry = user.custom_attributes.Country;
        // console.log(userCountry);
        // state or provence
        let userState = user.addr_state;
        // console.log(userState);
        // get state code
        let userStateCode = null;
        // console.log(`userStateCode NULL`);
        if (userState) {
          // console.log(`IF STATE`);
          // get state options if country is CA, JP, US
          let stateOptions = getStateOptions({ value: userCountry });
          // console.log(stateOptions);
          if (stateOptions) {
            // console.log(`STATE OPTIONS`);
            // Set state code if found
            if (userState.length > 2) {
              userStateCode = stateOptions.find(
                (x) => x.label === userState
              ).value;
            } else {
              userStateCode = userState;
            }
            // console.log(`set State Code`);
            // console.log("userStateCode: " + userStateCode);
            // console.log("userState: " + userState);
          }
        }
        // console.log(userStateCode);
        
        // Use language to get preferred language
        // default to en
        const userPreferredLanguage = user.language? getPreferredLanguage(user.language):"en";
        // console.log(userPreferredLanguage)
        // adding remaining user info
        console.log(`SET USER INFO`);
        const userInfo = {
          first_name: user.first_name,
          last_name: user.last_name,
          job_title: user.custom_attributes.Role,
          success_plan: user.user_success_plan
            ? user.user_success_plan
            : "standard",
          email: user.email,
          phone: user.mobile_phone,
          company: stringReplacer(user.custom_attributes.CompanyName),
          employees: user.custom_attributes.CompanySize,
          country: user.custom_attributes.Country,
          state: userStateCode,
          tb_id: user.tbid,
          avatar: user.custom_attributes.ProfilePictureUrl,
          language: userPreferredLanguage,
          locale: user.locale,
          preferred_language: user.preferred_language,
          timezone: user.timezone,
        };

        // console.log(userInfo);
        // set user info
        // console.log(`dispatch user info`);
        dispatch(setUserInfo(userInfo));

        // set user's preferred language and local
        let filterObject = {
          language: user.preferred_language,
          locale: user.locale,
        };
        dispatch(setUserLanguage(filterObject));

        // set api token on init
        if (user.api_token) {
          // console.log(`USER API TOKEN`);
          // set the user AIP token
          // console.log(`dispatch userApiToken`);
          dispatch(setUserApiToken({ token: user.api_token }));
        }
        // console.log(`redirect to path from state`);
        // redirecting with path from state
        document.location.href = `https://${CLIENT_DOMAIN}${user.path}`;
      } else {
        // console.log(`user unavailable, redirecting home`);
        // "user unavailable, redrecting to home"
        document.location.href = `https://${CLIENT_DOMAIN}`;
      }
    } catch (e) {
      console.log(`catch TBIDComplete error`);
      console.info(e);
    }
  });
  return <LoginPage />;
};
// call TBID complete
const TBIDCompleteContainer = connect()(TBIDComplete);

export default TBIDCompleteContainer;
