/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import "./ECCFilterTabs.scss";

export const ECCFilterTabs = (props) => {
  const {
    allController,
    coachingController,
    onboardingController,
    officeController,
    onTabChange,
  } = props;
  const [allState, setAllState] = useState(allController.state);
  const [eventsState, setEventsState] = useState(coachingController.state);
  const [portraitsState, setPortraitsState] = useState(onboardingController.state);
  const [coachingState, setCoachingState] = useState(officeController.state);

  const { t } = useTranslation();

  useEffect(() => {
    const allSubscribe = allController.subscribe(() =>
      setAllState(allController.state)
    );
    const eventsSubscribe = coachingController.subscribe(() =>
      setEventsState(coachingController.state)
    );
    const portraitsSubscribe = onboardingController.subscribe(() =>
      setPortraitsState(onboardingController.state)
    );

    const coachingSubscribe = officeController.subscribe(() =>
      setCoachingState(officeController.state)
    );

    return () => {
      allSubscribe();
      eventsSubscribe();
      portraitsSubscribe();
      coachingSubscribe();
    };
  }, [
    allController,
    coachingController,
    onboardingController,
    officeController,
  ]);

  const setTab = (key) => {
    onTabChange();
    switch (key) {
      case "expert-coaching":
        coachingController.select();
        break;
      case "guided-onboarding":
        onboardingController.select();
        break;
      case "office-hours":
        officeController.select();
        break;
      case "overview":
      default:
        allController.select();
        break;
    }
  };

  const scrollToTab = (tabId) => {
    const tabsElement = document.querySelector(".coveo--filter-tabs");
    const tabElement = document
      .getElementById(`filter-tabs-tab-${tabId}`)
      .getBoundingClientRect();
    tabsElement.scrollTo({
      top: tabElement.top,
      left: tabElement.left,
      behavior: "smooth",
    });
  };

  let tabKey = "overview";

  if (allController.state.isActive) {
    tabKey = "overview";
    setTimeout(() => {
      scrollToTab("overview");
    });
  }
  if (coachingController.state.isActive) {
    tabKey = "expert-coaching";
  }
  if (onboardingController.state.isActive) {
    tabKey = "guided-onboarding";
  }
  if (officeController.state.isActive) {
    tabKey = "office-hours";
    setTimeout(() => {
      scrollToTab("office-hours");
    });
  }

  return (
    <Tabs
      id="filter-tabs"
      className="coveo--filter-tabs"
      activeKey={tabKey}
      onSelect={(k) => setTab(k)}
    >
      {allController.state.isActive && <Tab eventKey="overview" title={t("Search All Results Tab")} />}
      {coachingController.state.isActive && <Tab eventKey="expert-coaching" title="Expert Coaching" />}
      {onboardingController.state.isActive && <Tab eventKey="guided-onboarding" title="Guided Onboarding" />}
      {officeController.state.isActive && <Tab eventKey="office-hours" title="Office Hours" />}
    </Tabs>
  );
};
